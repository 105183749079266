import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Meta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(Meta)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/demo',
    name: 'Demo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Demo.vue')
  },
  {
    path: '/faq',
    name: 'Faq',
    component: () => import(/* webpackChunkName: "about" */ '../components/Faq.vue')
  },
  {
    path: '/besonderheiten',
    name: 'Besonderheiten',
    component: () => import(/* webpackChunkName: "about" */ '../components/Besonderheiten.vue')
  },
  {
    path: '/features',
    name: 'Funktionalität',
    component: () => import(/* webpackChunkName: "about" */ '../components/Features.vue')
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: () => import(/* webpackChunkName: "about" */ '../components/Impressum.vue')
  },
  {
    path: '/agb',
    name: 'AGB',
    component: () => import(/* webpackChunkName: "about" */ '../components/AGB.vue')
  },
  {
    path: '/start',
    name: 'Startpunkt',
    component: () => import(/* webpackChunkName: "about" */ '../components/Start.vue')
  },
  {
    path:'/*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
