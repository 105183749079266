<template>
  <div class="home">
    <Landing />
    <Summary />
    <Responsive />
    <PricePrev />
    <Steps />
  </div>
</template>

<script>
// @ is an alias to /src
import Landing from '../components/LandingSection.vue'
import Summary from '../components/ShortSummary.vue'
import Responsive from '../components/ResponsiveSection'
import PricePrev from '../components/PricePreview'
import Steps from '../components/Steps'

export default {
  name: 'Home',
  components: {
    Landing,
    Summary,
    Responsive,
    PricePrev,
    Steps
  }
}
</script>
