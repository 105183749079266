<template>
  <div class="summaryWrapper">
    <div class="bottomBg">
      <img src="../assets/images/designs/down-convex.svg" alt="">
    </div>
    <div class="content">
        <h1>Drei gute Gründe für leamos</h1>
        <div class="reason">
            <div class="number">
                <img src="../assets/images/motivation.svg" alt="">
            </div>
            <div class="text">
                <h3>Belohnungssystem</h3>
                <p>Mit Freude lesen trainieren mit leamos. Sammle dabei Diamanten und Lesepunkte und erweitere täglich deinen Streak.</p>
            </div>
        </div>
        <div class="reason">
            <div class="number">
                <img src="../assets/images/buttons.svg" alt="">
            </div>
            <div class="text">
                <h3>Abwechslung</h3>
                <p>Variantenreich trainieren mit leamos. Worte, Pseudoworte und Symbole verbessern unterschiedliche Verarbeitungswege im Gehirn.</p>
            </div>
        </div>
        <div class="reason">
            <div class="number">
                <img src="../assets/images/user.svg" alt="">
            </div>
            <div class="text">
                <h3>Trainingsart</h3>
                <p>Lautes oder leises Lesen trainieren mit leamos. Acht verschiedene Arten des Einzeltrainings und Betreutes Lesen verbessern Aufmerksamkeit, Leseflüssigkeit und Visualisierung.</p>
            </div>
        </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'Summary',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/variables";
    .summaryWrapper{
position: relative;
  width: 100vw;
  min-height: 300px;
  background-color: $leamos-green-light;
  .content{
      padding-top: 1px;
      padding-bottom: 80px;
      width: 70vw;
      margin: auto;
      z-index: 2;
      @media screen and (max-width: $start-mobile) {
                    width: 90vw;
                }
      .reason{
          display: flex;
          text-align: left;
          width: 60%;
          @media screen and (max-width: 800px) {
                    width: 100%;
                }
          .number{
              font-size: 6rem;
              font-weight: bolder;
              display: flex;
              align-items: center;
            //   width: 150px;
            //   max-height: 200px;
            //   max-width: 200px;
            
              img{
                //   height: 100%;
                  width: 100%;
              }
          }
          .text{
              padding-left: 20px;
              max-width: 85%;
              min-width: 200px;
          }
          &:nth-of-type(2){
              margin-left: auto;
              @media screen and (max-width: 800px) {
                    margin-left: 0px;
                }
          }
      }
    //   color: white;
  }
  .bottomBg{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #f5f5f5;
    z-index: 1;
    img{
      width: 100%;
      height: 90px;
    }
  }
    }
</style>
