<template>
  <div class="footerWrapper">
      <div class="footerBg">
          <img src="../assets/images/designs/up-concave-dark.svg" alt="">
      </div>
      <div class="content">
          <div class="container">
              <h3>LEAMOS<br>Mag. Daniela Arnold</h3>
              <h4>support@leamos.at<br>+43 664 33 979 40</h4>

               <router-link to="/impressum">IMPRESSUM</router-link> <br>
               <router-link to="/datenschutz">DATENSCHUTZ</router-link> <br>
               <router-link to="/agb">AGB</router-link>
          </div>
          <div class="container">
              <h3 style="    margin-top: 49px;">SUPPORT & HILFE</h3>
              <router-link to="/start">Startpunkt auswählen</router-link> <br>
               <router-link to="/faq">Fragen & Antworten</router-link> <br>
               <!-- <router-link to="/konatkt">Kontat</router-link> -->
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/variables";
    .footerWrapper{
        background-color: $leamos-green;
        .footerBg{
height: 110px;
    background-color: #f5f5f5;
    img{
        height: 100%;
        width: 100vw;
    }
        }
        .content{
            min-height: 200px;
            width: 800px;
            display: flex;
            justify-content: space-between;
            margin: auto;
            text-align: left;
             @media screen and (max-width: 880px) {
         width: 90vw
      }
      .container{
          width: 45%;
          h3, h4{
              margin:5px 0px;
          }
      }
        }
    }
    a{
        color: white;
    }
</style>
