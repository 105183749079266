<template>
  <div class="landingWrapper">
    <div class="content">
      <div class="image">
        <!-- <img class="girl" src="../assets/images/girl.png" alt=""> -->
        <img class="boy" src="../assets/images/kid.png" alt="" />
        
      </div>
      <div class="text">
        <h1>leamos - Wir lernen lesen</h1>
        <h4>kurz - konzentriert – variantenreich – überall</h4>
      </div>
    </div>
    <div class="bottomBg">
      <img src="../assets/images/designs/up-concave.svg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Landing",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/variables";
.landingWrapper {
  position: relative;
  width: 100vw;
  height: 300px;
  background-color: #f5f5f5;
  .content {
    display: flex;
    width: 70vw;
    margin: auto;
     position: relative;
    @media screen and (max-width: $start-mobile) {
      width: 90vw;
    }
    .image {
      margin-top: -40px;
      height: 300px;
      position: relative;
      img {
        height: 100%;
      }

    
      @media screen and (max-width: 550px) {
        // width: 90vw;
        margin-left: -45px;
      }
      @media screen and (max-width: 450px) {
        // width: 90vw;
        margin-left: -65px;
      }
    }
   .text {
      text-align: right;
      width: calc(100% - 270px);
      position: absolute;
      right: 0;
      top: 0;
      // .text-content{

      // }
       @media screen and (max-width: 630px) {
        margin-top: -35px;
      }
      @media screen and (max-width: 550px) {
         width: calc(100% - 270px + 105px);
      }
       @media screen and (max-width: 450px) {
         h1{
           font-size: 1.5rem;
         }
         width: calc(100% - 270px + 115px);
      }
    }
  }
  .bottomBg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 120px;
    img {
      width: 100%;
      height: 120px;
    }
  }
  // background-image: url('../assets/images/designs/web-bgtop.svg');
  // // height: 300px;
}
</style>
