<template>
  <div class="sectionWrapper">
    <div class="content">
      <div class="image">
        <img src="../assets/images/responsive.png" alt="" />
      </div>
      <div class="text">
        <h2>Ist leamos eine App?</h2>
        <p>
        leamos wurde mit der neuesten Technik entwickelt, Sie müssen kein Programm oder App
          extra herunterladen.
        </p>
        <h2>
          Ist leamos ein Spiel?
        </h2>
        <p>
         
          Nein, leamos bedeutet hartes Training. leamos ist für Klienten, die Schwierigkeiten in der Aufmerksamkeit, Lesegenauigkeit, Lesegeschwindigkeit und Visualisierung haben. Auch bei einer diagnostizierten Legasthenie
          oder eine Lese-Rechtschreibschwäche kann es als Unterstützung eingesetzt werden. Aber
          auch Erwachsene können mit leamos ihre Lesefertigkeiten verbessern. 
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/variables";
.sectionWrapper {
  background-color: #f5f4f4bb;
  .content {
    width: 70vw;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: $start-mobile) {
      width: 90vw;
    }
    .image {
      display: flex;
      align-items: center;
      width: 70%;
      max-width: 500px;
      @media screen and (max-width: $small-pre-mobile) {
        width: 50%;
      }
      @media screen and (max-width: 800px) {
        width: 100%;
      }
      img {
        // max-width: 500px;
        width: 100%;
      }
    }
    .text {
      width: calc(100% - 500px);
      @media screen and (max-width: $small-pre-mobile) {
        width: 50%;
      }
      @media screen and (max-width: 800px) {
        width: 100%;
      }
      text-align: justify;
    }
  }
}
</style>
