<template>
<div class="sidebarWrapper" id="sidebar">
    <div style="padding: 0 30px">
      <h1 style="text-align: left" v-on:click="toggleSidebar()">&times;</h1>
      <div class="menu">
        <img src="../assets/images/logos/leamosLogo.svg" alt="" />
        <router-link to="/"
          ><h2 v-on:click="toggleSidebar()">Home</h2></router-link
        >
         <div class="devider"></div>
        <router-link to="/features"
          ><h2 v-on:click="toggleSidebar()">Funktionalität</h2></router-link
        >
        <div class="devider"></div>
        <router-link to="/besonderheiten"
          ><h2 v-on:click="toggleSidebar()">Besonderheiten</h2></router-link
        ><!--
        <div class="devider"></div>
        <router-link to="/demo"
          ><h2 v-on:click="toggleSidebar()">Demo</h2></router-link
        > -->
        <div class="devider"></div>
        <router-link to="/faq"
          ><h2 v-on:click="toggleSidebar()">FAQ</h2></router-link
        >
        <!-- <div class="devider"></div>
        <router-link to="/contacto"
          ><h2 v-on:click="toggleSidebar()">Contacto</h2></router-link
        > -->
        <!-- <div class="auth-btn">
          <a href="https://app.miclamo.com/login" target="_blank" role="button">
            <button class="login-btn">
              <h2 style="margin: 0; color: white">Login</h2>
            </button>
          </a>
        </div>
        <div class="auth-btn yellow">
          <a
            href="https://app.miclamo.com/clamo/busca-compania"
            target="_blank"
            role="button"
          >
            <button class="login-btn yellow">
              <h2 style="margin: 0; ">Reclamar</h2>
            </button>
          </a>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  methods: {
    toggleSidebar: function () {
      document.getElementById("sidebar").classList.toggle("active");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables";
.sidebarWrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  right: -100vw;
  top: 0;
  background-color: white;
  transition: all 0.5s;
  z-index: 100;
  &.active {
    right: 0;
  }
  .menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      height: 100px;
    }
    h2 {
      margin: 10px 0;
    }
    .devider {
      width: 60px;
      border-radius: 5px;
      height: 3px;
      background-color: #dd5369;
    }
  }
}
</style>