<template>
  <div id="app">
    <Navbar />
    <router-view class="router"/>
    <Footer />
    <Sidebar />
    <!-- <div style="height: 1000px"></div> -->
    <!-- <div class="construction">
      <img src="./assets/images/construction.svg" alt="">
    </div> -->
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue';
import Sidebar from './components/Sidebar'

export default {
  name: 'Home',
  components: {
    Navbar,
    Footer,
    Sidebar
  }
}
</script>


<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Sulphur+Point:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sulphur+Point:wght@300;400;700&family=Ubuntu:wght@400;500;700&display=swap');

#app {
  font-family: Ubuntu, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f5f5f5;
}
router-link, a{
  text-decoration: none;
}
.construction{
  height: 300px;
  z-index: 100;
  position: fixed;
  bottom: 0px;
  right: 0px;
}
// .router{
//   margin-top: 100px;
// }

// #nav {
//   padding: 30px;
// }

// #nav a {
//   font-weight: bold;
//   color: #2c3e50;
// }

// #nav a.router-link-exact-active {
//   color: #42b983;
// }
</style>
