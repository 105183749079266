<template>
  <div>
  
    
    <h3>Im Nu zum Lesetraining</h3>
    <h4>1. leamos User anlegen </h4>
    <h4>2. Lizenz auswählen </h4>
    <h4>3. Auswahl der Startschwierigkeit (Student, Praktikant, Trainee) </h4>
    <h4>4. Loslegen</h4>
    <p>
      Tagesziel an 5 oder 6 Tagen pro Woche erreichen: <br> 8 Trainings mit Lesepartner im Betreuten Lesen <br>
	15 Einzeltraining alleine durchführen
  <br> und jeden Tag Diamanten und Lesepunkte sammelen <br> sowie deinen Streak verbessern 

    </p>




  </div>
</template>

<script>
export default {
  name: 'Steps',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
 div{
        width: 70vw;
        margin: auto;
        // text-align: left;
    }
</style>
