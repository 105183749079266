<template>
  <div class="pPreviewWrapper">
    <div class="top">
      <img src="../assets/images/designs/up-convex.svg" alt="" />
    </div>
    <div class="content">
      <h2>Unglaubliches Angebot zu unglaublichem Preis</h2>
      <div class="priceCardContainer">
        <div class="priceCard">
          <img
            style="width: 100px"
            src="../assets/images/logos/leamosLogo.svg"
            alt="leamos - Lesetrainer"
          />
          <h4 style="margin-top: 5px">Leamos - Premium</h4>
          <!-- <p>Abonnement: <strong>monatlich</strong></p> -->
          <p>Preis: <br /><strong>5,80€</strong>/Monat</p>
        </div>
        <div class="priceCard">
          <img
            style="width: 100px"
            src="../assets/images/logos/leamosLogo.svg"
            alt="leamos - Lesetrainer"
          />
          <h4 style="margin-top: 5px">Leamos - Premium</h4>
          <!-- <p>Abonnement: <strong>monatlich</strong></p> -->
          <p>Preis: <br /><strong>54€</strong>/Jahr</p>
          <div class="trapezoid">22% Ersparniss</div>
        </div>
      </div>
      <div class="coupon">
        <h1>leamos wird ab dem 15.12.2020 verfügbar sein:</h1>
        <h2>
          Willst du benachrichtigt werden und einen Coupon für bis zu -50%*
        </h2>
        <form
          action="/"
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
        <input type="hidden" name="form-name" value="contact">
          <p>
            <label>Dein Name: <input type="text" name="name" /></label>
          </p>
          <p>
            <label
              >Deine Email: <input type="email" name="email" required
            /></label>
          </p>
          <!-- <p>
    <label>Your Role: <select name="role[]" multiple>
      <option value="leader">Leader</option>
      <option value="follower">Follower</option>
    </select></label>
  </p>
  <p>
    <label>Message: <textarea name="message"></textarea></label>
  </p> -->
          <p>
            <button
              type="submit"
              style="
                cursor: pointer;
                background-color: #f5f5f5;
                outline: none;
                border: 0px;
                padding: 5px 10px;
                border-radius: 5px;
                box-shadow: 0 0 10px 2px lightgray;
              "
            >
              Coupon sichern!
            </button>
          </p>
        </form>
        <p style="font-size: 0.7rem">
          *50% gültig auf die ersten drei Monate bei einem Monats-Abo.
        </p>
      </div>

      <div class="text">
        <h3 style="text-align: center">Benefits:</h3>
        <ul>
          <li>Wahlweise auf allen Geräten</li>
          <li>Wenig Zeitaufwand</li>
          <li>Viel Abwechslung</li>
          <li>Hohe Motivation</li>
          <li>Automatisierte Sichtwörter</li>
          <li>Abrufbare Wortbilder</li>
          <li>Gedächtnistraining</li>
          <!-- <li>...</li> -->
        </ul>
        <router-link to="/besonderheiten"
          ><h4>
            Ich will mehr über die Funktionalitäten wissen...
          </h4></router-link
        >
      </div>
    </div>
    <div class="bottom">
      <img src="../assets/images/designs/down-convex.svg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "PricePrev",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/variables";

.coupon {
  margin-top: 25px;
  background-color: #47c94d;
  padding-top: 20px;
  border-radius: 20px;
  h1 {
    color: rgb(255, 222, 77);
  }
  input {
    border: 1px solid lightgray;
    outline: none;
    box-shadow: inset 0 0 10px lightgrey;
    border-radius: 5px;
    padding: 5px 10px;
  }
}
.pPreviewWrapper {
  background-color: #b9eabb;
  .top {
    background-color: #f5f5f5;
    width: 100%;
    height: 120px;
    img {
      width: 100%;
      height: 120px;
    }
  }
  .content {
    width: 70vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: $start-mobile) {
      width: 90vw;
    }
    .priceCardContainer {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
      .priceCard {
        background-color: #f5f5f5;
        padding: 10px 10px;
        border-radius: 5px;
        width: 150px;
        box-shadow: 1px 1px 3px lightgray;
        transition: all 0.5s ease-out;
        cursor: pointer;
        border: solid 1px #8383837a;
        box-shadow: 1px 1px 3px 3px #8383833d;
        background-color: white;
        border-radius: 5px;
        padding: 20px 3%;
        // max-width: 45%;
        width: 40%;
        max-width: 300px;
        text-align: left;
        margin: auto;
        // transform: scale(1);
        transition: all 0.5s ease-out;
        position: relative;
        @media screen and (min-width: $start-mobile) {
          &:hover {
            transform: scale(1.1);
          }
        }

        .trapezoid {
          border-bottom: 30px solid #ffd000;
          border-left: 30px solid transparent;
          border-right: 30px solid transparent;
          height: 0;
          width: 75px;
          position: absolute;
          right: -31px;
          top: 21px;
          text-align: center;
          transform: rotate(45deg);
          font-size: 0.8rem;
        }
      }
    }
    .text {
      align-self: center;
      text-align: left;
    }
  }

  .bottom {
    background-color: #f5f5f5;
    width: 100%;
    height: 120px;
    img {
      width: 100%;
      height: 120px;
    }
  }
}
</style>
